import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'
import { useLinks } from 'src/lib/hooks/useLinks'

import { colors, dimensions } from '#styles'

import Play from '../../../public/assets/common/play.svg'
import GeoLoc from '../../../public/assets/Franchise/partnership-network.png'
import ChargingImage from '../../../public/assets/Localized/charging-image.png'
import Condo from '../../../public/assets/stations/condo.png'
import Hut from '../../../public/assets/stations/hut.png'
import Skyscraper from '../../../public/assets/stations/skyscraper.png'
import Totem from '../../../public/assets/stations/totem.png'
import Tower from '../../../public/assets/stations/tower.png'
import Button from '../../components/common/Button/Button'
import * as Hero from '../../components/common/Hero'
import { TextContainer } from '../../components/common/Hero'
import LinkButton from '../../components/common/LinkButton/LinkButton'
import { PageWrapper, StyledImage } from '../../components/common/styled'
import VideoModal from '../../components/common/VideoModal/VideoModal'
import Container from '../../components/Container'
import BestChargingSolution from './BestChargingSolution/BestChargingSolution'
import SetupPitches from './SetupPitches/SetupPitches'
import * as S from './styled'

const VenueSection = dynamic(
  () => import('../../components/VenueSection/VenueSection')
)

const StationsSection = dynamic(
  () => import('../../components/StationsSection/StationsSection')
)

const Solution = dynamic(() => import('./HomeLocalized/Solution'))
const Deals = dynamic(() => import('./HomeLocalized/Deals'))
const LearnMore = dynamic(() => import('./HomeLocalized/LearnMore'))

type Props = {
  country: string
}

const HomeLocalized: FC<Props> = ({ country }) => {
  const { t } = useTranslation('home-localized')

  const links = useLinks()

  const [videoOpen, setVideoOpen] = useState(false)

  return (
    <PageWrapper>
      <Container
        backgroundColor={colors.black}
        className="column-reverse laptop-row"
      >
        <TextContainer gap="1rem">
          <Hero.TextHeader color={colors.white} size="lg">
            {t(`hero.header`)}
          </Hero.TextHeader>
          <Hero.TextBody color={colors.white}>{t('hero.body')}</Hero.TextBody>
          <Hero.ButtonContainer padding="1rem 0 0 0" justifyStart>
            <LinkButton
              href={links.home_localized.section_1.link}
              id="primary-apply-banner"
            >
              {t('hero.apply_link')}
            </LinkButton>
            <Button
              id={'secondary-play_video-banner'}
              transparent
              textColor={colors.greenAccent}
              onClick={() => setVideoOpen(true)}
            >
              <Image priority alt={t('hero.chevron_alt')} src={Play} />
              {t('hero.what_is_link')}
            </Button>
          </Hero.ButtonContainer>
        </TextContainer>
        <Hero.ImageWrapper>
          <StyledImage
            src={GeoLoc}
            priority
            quality={40}
            alt={t('hero.image_alt')}
            placeholder="blur"
            sizes={`(min-width: ${dimensions.laptop}) 40vw,(min-width: ${dimensions.tablet}) 60vw, 80vw`}
          />
        </Hero.ImageWrapper>
        <VideoModal
          open={videoOpen}
          handleClose={() => setVideoOpen(false)}
          videoUrl="../assets/Videos/brick-commercial-full-day"
        />
      </Container>
      <Container
        backgroundColor={colors.primaryBlack900}
        className="column laptop-row"
      >
        <SetupPitches
          pitches={[t('pitches.grid1'), t('pitches.grid2'), t('pitches.grid3')]}
        />
      </Container>
      <S.LocalizedContainer backgroundColor={colors.white} className="column">
        <BestChargingSolution
          image={ChargingImage}
          solutionPros={[
            t('charging_solution.list1'),
            t('charging_solution.list2'),
            t('charging_solution.list3'),
          ]}
        >
          <Hero.TextContainer center>
            <Hero.TextHeader>
              {t('charging_solution.header', {
                country,
              })}
            </Hero.TextHeader>
          </Hero.TextContainer>
        </BestChargingSolution>
      </S.LocalizedContainer>
      {/* FOLD */}
      <Container backgroundColor={colors.primaryBlack900} className="column">
        <VenueSection
          header={t('learn_more_charging.header')}
          items={[
            {
              name: t('learn_more_charging.button_text1'),
              href: links.home_localized.section_4.airports.link,
            },
            {
              name: t('learn_more_charging.button_text2'),
              href: links.home_localized.section_4.restaurants.link,
            },

            {
              name: t('learn_more_charging.button_text3'),
              href: links.home_localized.section_4.bars.link,
            },
            {
              name: t('learn_more_charging.button_text4'),
              href: links.home_localized.section_4.malls.link,
            },
            {
              name: t('learn_more_charging.button_text5'),
              href: links.home_localized.section_4.arenas.link,
            },
            {
              name: t('learn_more_charging.button_text6'),
              href: links.home_localized.section_4.venues.link,
            },
          ]}
        />
      </Container>
      <S.LocalizedContainer
        backgroundColor={colors.neutral50}
        className="column"
      >
        <StationsSection
          header={t('stations.header')}
          linkText={t('stations.contact_us')}
          stationCards={[
            {
              ...t('stations.items.hut'),
              img: Hut,
            },
            {
              ...t('stations.items.condo'),
              img: Condo,
            },
            {
              ...t('stations.items.totem'),
              img: Totem,
            },
            {
              ...t('stations.items.tower'),
              img: Tower,
            },
            {
              ...t('stations.items.skyscraper'),
              img: Skyscraper,
            },
          ]}
        />
      </S.LocalizedContainer>

      <Solution t={t} />
      <Deals t={t} />
      <LearnMore t={t} />
    </PageWrapper>
  )
}

export default HomeLocalized
