import type { NextPage } from 'next'
import { GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'

import { i18n } from '../../next-i18next.config'
import { PageWrapper } from '../components/common/styled'
import HeadComp from '../components/HeadComp/HeadComp'
import { fetchForceAppless } from '../lib/api/market'
import { Paths } from '../lib/constants'
import { createLangHrefs } from '../lib/createLangHrefs'
import {
  formatServerSideTranslations,
  getLocalizedCountryName,
  ignoreLocale,
} from '../lib/i18n'
import { PageProps } from '../types/pages'
import HomeGeneral from './home/HomeGeneral'
import HomeLocalized from './home/HomeLocalized'

type Props = PageProps & {
  countryName: string | null
}

const Home: NextPage<Props> = ({ langHrefs, applessOnly, countryName }) => {
  const { t } = useTranslation('home')
  return (
    <PageWrapper>
      <HeadComp
        title={t('head.title')}
        description={t('head.description')}
        langHrefs={langHrefs}
      />
      {!countryName ? (
        <HomeGeneral applessOnly={applessOnly} />
      ) : (
        <HomeLocalized country={countryName} />
      )}
    </PageWrapper>
  )
}

export const getStaticProps: GetStaticProps<Props> = async ({
  locale = i18n.defaultLocale,
  locales,
}) => {
  return {
    props: {
      ...(await formatServerSideTranslations(locale, [
        'common',
        'navigation',
        'home',
        'home-localized',
      ])),
      langHrefs: createLangHrefs(Paths.HOME, locale, locales),
      locale,
      countryName: getLocalizedCountryName(locale),
      applessOnly: await fetchForceAppless(locale),
      button: 'APP',
    },
    notFound: ignoreLocale(locale, ['default']),
    revalidate: 60 * 60,
  }
}

export default Home
